.product {


    // overflow: hidden;
    // @include round(8px);
    // padding: 5px;
    // border: 1px solid transparent;
    //
    // transition: all 0.2s ease;
    //
    // image {
    //     width: 150px;
    // }


    .thumbnail {
        overflow: hidden;
        @include round(8px);
        padding: 5px;
        border: 1px solid transparent;
        transition: all 0.2s ease;

        &:hover {
            border: 1px solid #e5e5e5;
            text-decoration: none;
            @include box-shadow(0px, 3px, 20px, rgba(0,0,0,0.3));
        }

        .img-wrapper {
            position: relative;
            width: 100%;
            display: block;

            .caption {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 35px;
                color: $light;
                @include gradient(rgba(255,255,255,0),rgba(0,0,0,0.6));
            }

            img {
                @include round(5px);
                @include round-corner(bottom-left, 0px);
                @include round-corner(bottom-right, 0px);
                width:200px;
                height:150px;
            }
        }
    }


}
