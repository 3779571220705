.auth {

    .root {
        padding: 50px 0 40px;
    }

    .no-plan {
        font-weight: normal;
        strong {
            margin-right: 30px;
        }
    }

    .panel-login {

        height: calc(100vh - 190px);
        

        .panel {

            width: 800px;
            height: 550px;
            border: none;
            padding: 0px;
            margin: 0px;
            margin-top: -170px;
            overflow: hidden;
            @include round(20px);
            @include box-shadow(0px, 30px, 90px, rgba(0,0,0,0.2));

            flex-grow: 1 2;

            .form-wrap {
                min-width: 350px;
                padding: 20px;
                margin-top: 0px;

                .form {
                    margin-left: 5%;
                    width: 90%;
                }
            }

            .image-wrap {
                position: relative;
                flex-grow: 1;
                background: rgb(240,249,255);
                background: -moz-linear-gradient(-45deg,  rgba(240,249,255,1) 0%, rgba(203,235,255,1) 47%, rgba(161,219,255,1) 100%);
                background: -webkit-linear-gradient(-45deg,  rgba(240,249,255,1) 0%,rgba(203,235,255,1) 47%,rgba(161,219,255,1) 100%);
                background: linear-gradient(135deg,  rgba(240,249,255,1) 0%,rgba(203,235,255,1) 47%,rgba(161,219,255,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f9ff', endColorstr='#a1dbff',GradientType=1 );

                img {
                    width: 350px;
                }
            }
        }

    }
}

// mobile fix
@media only screen and (min-width : 320px) {
    .auth {
        .panel-login {
            .panel {
                width:100%;
                height: 60%;
            }
        }
    }
}
