
.drop-container {
    position: relative;
    background: #f5f5f5;
    color: #000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: auto;
    padding: 5px;
    border: 2px dashed #ccc;
    cursor: pointer;
    min-height: 110px;
    min-width: 128px;
    @include round(10px);

    .description {
        position: absolute;
        z-index: 1;
        text-align: center;
        width: 110px;
        height: 70px;
        left: 50%;
        margin-left: -55px;
        top: 50%;
        margin-top: -35px;
    }

    img {
        @include round(5px);
        margin: 2px;
        width: 110px;
        height: 110px;
        z-index: 2;
    }
}


.card {
    display: block;
    width: 300px;
    height: 230px;
    background: $light;
    @include box-shadow(0px, 10px, 20px, rgba(0,0,0,0.2));
    @include round(5px);
    overflow: hidden;
    zoom: 65%;

    h3 {
        font-size: 1em;
        font-weight: normal;
        font-family: 'Avenir Next','AvenirNextLTW01-Regular',Helvetica,Arial,sans-serif;
        margin: 0px 0px 10px 0px;
        color: #000000;

        &.price {
            color: #c70000;
            font-weight: bold;
            font-size: 1.4em;
            margin-top: 10px;
        }
    }

    p {
        font-size: 0.8em;
    }

    .hold-image {
        height: 170px;
        overflow: hidden;
        background: #f1f1f1;
        img {
            margin-top: -100px;
        }
    }

    .description {
        font-size: 1em;
        padding: 10px;
    }
}

.preview {
    display: inline-block;
    position: relative;
    width: 300px;
    height: 579px;
    padding: 0px;
    text-align: center;
    zoom: 1;

    .wrapper {
        background: $light;
        position: absolute;
        height: 416px;
        left: 33px;
        top: 67px;
        width: 234px;
        overflow: hidden;
        text-align: left;

        .hold-image{
            height:234px;
            overflow: hidden;
            img {
                width: 234px;
            }
        }

        .description {
            font-size: 0.6em;
            padding: 5px;
            border-bottom: 1px solid #e6e6e6;
        }

        .icons {
            font-size: 0.6em;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content:center;
            border-bottom: 1px solid #e6e6e6;
            padding: 5px;
            .icon {
                margin: 0px 10px;
                .fa {
                    display: block;
                    font-size: 1.5em;
                }
            }
        }

        .info {
            padding: 5px;
            border-bottom: 1px solid #e6e6e6;
            position: relative;
            font-size: 0.7em;

            h1 {
                font-family: 'Avenir Next','AvenirNextLTW01-Regular',Helvetica,Arial,sans-serif;
                font-weight: normal;
                font-size: 1em;
                color: #000;
                margin: 0px;
                margin-bottom: 3px;

                &.price {
                    text-align: right;
                    font-size: 1.2em;
                    position: absolute;
                    right: 5px;
                    top: 20px;
                    color: #d00000;
                    font-weight: bold;
                }
            }

            p {
                font-size: 0.8em;
                margin: 0px 0px 5px 0px;
            }

            .company {
                font-size: 0.8em;
                img {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    overflow: hidden;
                    @include round(3px);
                    margin-right: 5px;
                }
            }
        }


    }

    > img {
        width: 300px;
        width: 300px;
        padding: 0px;
        margin: -15px 0px 0px 0px;
        z-index: 2;
        position: absolute;
        left: 0px;
        top: 0px;
    }

}
