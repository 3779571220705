@import 'bower_components/sass-material-colors/sass/sass-material-colors';

$main-color  : #31639B;
$second-color: material-color('blue', '200');

$accent-color: #4a9cc4;
$third-color: #F05C2F;

$dark        : #464a5c;
$grey        : material-color('grey');
$line-grey   : material-color('grey','200');
$light-grey  : material-color('grey','100');
$green       : material-color('green');
$light-green : material-color('green', '200');
$yellow      : material-color('yellow');
$red         : material-color('red');
$light-red   : material-color('red', '200');
$light       : #ffffff;
$border      : #f2f2f2;
$super-light-blue: material-color('blue', '50');
$light-blue  : material-color('blue', '100');
$blue  : material-color('blue', '300');;

html {
    position: relative;
    min-height: 100%;
}

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    padding: 0;
    font-family: Circular, Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #171717;

    > .root {
        padding: 50px 0 140px;
        min-height: 768px;

        .container {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .breadcrumb-wrapper {
            margin-bottom: 0;
            border-bottom: 1px solid #f9f9f9;

            .container {
                padding: 0px 15px;
            }

            .breadcrumb {
                background: transparent;
                margin: 0px auto;
                padding: 10px 0px;
            }
        }
    }

    &.chat {
        > .root {
            padding-top: 60px;
        }
    }
}

.menu-lateral{
    body {
        font-family: "Lato", sans-serif;
      }
      
      .sidenav {
        height: 100%;
        width: 380px;
        position: fixed;
        z-index: 1;
        margin-top: -15px;
        left: 0;
        background-color:#f5f5f5;
        // overflow-x: hidden;
        padding-top: 30px;
        h4{
            padding-left: 30px;
        }
      }
      
      .sidenav ul li {
        text-decoration: none;
        font-size: 20px;
        color: black;
        display: block;
        cursor: pointer;
      }
      
      .sidenav ul li:hover {
        background-color: #f1f1f1;
      }
      
      .main {
        margin-left: 160px; /* Same as the width of the sidenav */
        font-size: 28px; /* Increased text to enable scrolling */
        padding: 0px 10px;
        width: 100%;
        height: 100%;

        .layout-preview{
            width: 100%;
            height: 100vh;
            overflow: auto;
        }

      
    }
}
