@import 'config';
@import 'typography';
@import 'mixins';
@import 'elements/navbar';
@import 'elements/container';
@import 'elements/banner';
@import 'elements/footer';
@import 'elements/dashboard';
@import 'elements/general';
@import 'elements/card';
@import 'elements/table';
@import 'elements/buttons';
@import 'elements/forms';
@import 'elements/modal';
@import 'elements/dropdown';
@import 'elements/switcher';
@import 'elements/tabs';
@import 'elements/charts';
@import 'elements/product';
@import 'elements/iframe';
@import 'elements/pretty-list';
@import 'elements/picture-show';
@import 'elements/schedule';
@import 'transitions';

@import 'pages/auth';
@import 'pages/profile';
@import 'pages/client';
@import 'pages/chat';
@import 'pages/financial';
@import 'pages/home';
@import 'pages/notifications';
@import 'pages/contract';
@import 'pages/cart';
@import 'pages/signup';
@import 'pages/balance';
@import 'pages/client_room';
@import 'pages/service';
@import 'pages/offer';
@import 'pages/checkout';
@import 'pages/register_step';
@import 'pages/onboarding';
@import 'pages/cashier';

@import 'responsive';

* {
  outline: none !important;
}
