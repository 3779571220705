.cart-page {
    width: 700px;
    margin: 0 auto;
    padding: 0px;

    .panel-default > .panel-heading {
        background-color: white;
    }

    .cart-resume {
        background: #E3F2FD;
        border-top: 1px solid #BBDEFB;
        height:70px;
        line-height: 20px;
        padding-top:10px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index:999;

        .content {
            width: 700px;
            margin: 0 auto;
        }

        strong {
            color: #0D47A1;
        }
    }
}
