.checkout {
    // .container {
    //     margin-left: auto;
    //     margin-right: auto;
    //     display: block;
    //     width: 100%;
    // }

    // .logo {
    //     align-items: center;
    //     display: flex;
    //     max-width: 150px;
    //     justify-content: flex-start;
    //     padding-left: 100px;
    // }


    .header__right {
        display: flex;
        align-content: center;

    }

    h1.bottom{
        font-size: 30px !important;
        margin-top: 0 !important;
    }

    .waiting {
        font-size: 5px;
    }

    wz.compra {
        font-size: 10px;
    }

    .buy h2 {
        overflow: auto !important;
        height: 80%;
        font-size: 20px;
    }

    .table,
    td,
    tr {
        border-radius: 5px;
        margin: 0 auto;
        float: none;
        margin-right: 10px;
        font-size: 13px;
    }

    td.zoom {
        overflow: visible;
    }

    td.zoom img {
        width: 35px;
        max-width: 100%;
        -moz-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    td.zoom:hover img {
        -moz-transform: scale(4.1);
        -webkit-transform: scale(4.1);
        transform: scale(4.1);
    }

    .descripition {
        margin-top: 30px;
        font-size: 30px;
        display: block;
        align-content: center;
    }

    td.price {
        font-size: 30px;
    }

    h1,
    span {
        font-size: 1.2em;
        margin-top: 20px;
    }

    h3 {
        margin-top: 30px;
    }

    .payment {
        padding-top: 40px;
    }

    .jp-card-container {
        transform: scale(0.8) !important;
    }

    .panel-body a {
        margin: -25px 10px 10px;
    }

    .panel-default {
        margin-top: 30px;
    }

    .buyer h3 {
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 25px;
        overflow: auto !important;
        height: 80%;
    }
}
