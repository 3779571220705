.schedule {
    .fc-content{
        line-height: 10px !important;
    }
    .schedule ul {
        width: 220px;
        display: table;
        cursor: pointer;
        li {
            display:table-cell;
            list-style: none;
            font-size: 14px;
        }
        li.active{
            animation: round($number: 1);
            background-color: green;
            font-size: 20px;
            font-weight: bold;
            text-decoration: underline;
        }
    }
    .opened {
        background-color: #dcdcdc;
        border-color: white;
        color: black;
    }
    .lack {
        background-color: #808080;
        border-color: white;
        color: white;
    }
    .late {
        background-color: #FF6347;
        border-color: white;
        color: white;
    }
    .unchecked {
        background-color: #4F4F4F;
        border-color: white;
        color: white;
    }
    .unchecked-patient {
        background-color: #4F4F4F;
        border-color: white;
        color: white; 
    }
    .unchecked-professional {
        background-color: #212121;
        border-color: white;
        color: white; 
    }
    .finished {
        background-color: #2B9994;
        border-color: white;
        color: white;
    }
    .checked {
        background-color: #57A300;
        border-color: white;
        color: white;
    }
    .in_progress {
        background-color: #386CE6;
        border-color: white;
        color: white;
    }
    .canceled {
        background-color: #808080;
        border-color: white;
        color: white;
    }
    .confirmed {
        background-color: #d4b746;
        border-color: white;
        color: white;
    }
    .block {
        background-color: red;
        border-color: white;
        color: white;
    }
    .all {
        background-color: #255224;
        border-color: white;
        color: white;
    }
    .float {
        position: fixed;
        width: 40px;
        height: 40px;
        right: 35px;
        top: 60px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 40px;
        text-align: center;
        font-size: 20px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100; 
    }
    .button-duda {
        background-color: #2B9994;
        color: #FFFFFF;
    }
}
