%top-notch {
    content: " ";
    position: absolute;
    display: block;
    height: 4px;
    background: $light;
    width: 100%;
    left: 0px;
    top: 0px;
    box-shadow: 0 5px 14px rgba(0,0,0, .3), 0 1px 3px rgba(0,0,0, .4);
    @include round-corner('bottom-left', 3px);
    @include round-corner('bottom-right', 3px);
}

.navbar {

    // background-color: $main-color;
    background: #489bc2;
    background: -moz-linear-gradient(left, #489bc2 0%, #2f639c 100%);
    background: -webkit-linear-gradient(left, #489bc2 0%,#2f639c 100%);
    background: linear-gradient(to right, #489bc2 0%,#2f639c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#489bc2', endColorstr='#2f639c',GradientType=1 );

    border: 0px;
    box-shadow: 0 12px 60px rgba(25,73,155, .04);
    -webkit-transition: all .5s cubic-bezier(.35, 0, .25, 1);
    transition: all .5s cubic-bezier(.35, 0, .25, 1);

    .navbar-brand {
        line-height: 100%;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        min-height: 50px;
        img {
            height: 35px;
            width: auto;
        }

        .divider {
            height: 20px;
            width: 1px;
            background-color: rgba(255,255,255,0.4);
            margin-left: 15px;
            margin-right: 15px;
            display: inline-block;
            vertical-align: middle;

            display: none;
            margin: 0px;
        }
    }

    .navbar-nav {
        > li {

            > p {
                white-space: nowrap;
                line-height: 50px;
                padding: 0px 15px;
                letter-spacing: .01em;
                color: $light;
                margin: 0px;
            }

            > a {
                white-space: nowrap;
                line-height: 50px;
                padding: 0px 15px;
                text-align: center;
                font-size: 1.15em;
                font-weight: bold;
                letter-spacing: .01em;
                text-transform: uppercase;
                // color: $light-blue;
                cursor: pointer;

                &.active, &:hover {
                    color: $light;
                    &:before {
                        @extend %top-notch
                    }
                }

                &.new_message::after {
                    display: block;
                    content: '';
                    @include round(50%);
                    width: 15px;
                    height: 15px;
                    background: $red;
                    position: absolute;
                    top: 17%;
                    right: 19%;
                    border: 2px solid $main-color;
                }

                &.btn {
                    height: 35px;
                    padding: 5px 20px;
                    margin-top: 14px;
                    border: 0px;
                    color: $main-color;
                }

                &.btn-signin {
                    font-size: 1em !important;
                    margin: 0px;
                    line-height:30px;
                    padding: 0px 20px;
                    margin-top: 7px;
                    background-color: transparent;
                    border: 1px solid $light;
                    color: $light;
                    text-transform: inherit;
                }

                &.boleto_vencendo{
                    background-color: $third-color !important;
                    color: white !important;
                    line-height: 25px !important;
                    text-transform: inherit !important;
                    margin-top: 13px !important;
                    padding: 0px 15px !important;
                    height: 25px !important;
                    margin-right: 25px !important;
                }

            }

            a.whats {
                color: #81C784;
                &:hover {
                    color: #76FF03;
                }
            }



            &.open {
                > a, > a:hover {
                    background: transparent;
                    color: $light;

                    &:before {
                        @extend %top-notch
                    }
                }
            }
        }

        .dropdown-menu {

            border-width: 0px;
            box-shadow: 0 10px 24px rgba(25,73,155, .08), 0 4px 13px rgba(25,73,155, .04);
            border-top: 1px solid #f2f2f2;
            @include round(5px);
            margin-top: -5px;
            margin-left: 4px;

            &.dropdown-menu-center {
                width: 190px;
                left: -70px;
            }

            &:before {
                display: block;
                content: "";
                position: absolute;
                top: -5px;
                left: 50%;
                width: 10px;
                height: 10px;
                margin-left: -10px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                background-color: #fff;
                box-shadow: -3px -3px 5px rgba(63, 49, 178, .06);
            }

            > li {
                a {
                    background-color: $light;
                    border: none;
                    // color: $dark;
                    padding: 5px 15px 5px 15px;

                    &.icon-left {
                        padding: 5px 15px 5px 35px;
                    }

                    &:hover {
                        background-color: $main-color;
                        color: $light;
                    }
                }
            }
        }


        &.navbar-right {

            > li > a {
                &:before {
                    display: none !important;
                }
            }

            .dropdown-menu::before {
                right: 43px;
                left: inherit;
            }
        }

    }

    .user-avatar {
        overflow: hidden;
        width: 25px;
        height: 25px;
        @include round(5px);
        margin-right: 10px;
    }

    .notifications {

        .counter {
            color: $light;
            background-color: #F44336;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            right: 8px;
            width: 18px;
            height: 18px;
            line-height: 18px;
        }
        .dropdown-menu {
            max-height:350px;
            overflow: auto;
            max-width: 450px;

            //style scrollbar chrome
            &::-webkit-scrollbar {
                width: 1em;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #eee;
                outline: 1px solid slategrey;
            }

            li {
                width: 100%;
                overflow: hidden;
                display: block;
                word-wrap: break-word;
                overflow-wrap: break-word;
                padding: 0px;
                margin: 0px;

                border-top: 1px solid #eaeaea;

                &:first-child {
                    border-top: none;
                }

                a  {
                    white-space: normal;
                    text-decoration: none;
                    padding: 5px 10px;
                    min-width: 400px;
                    display: flex;
                    align-items: center;

                    p {
                        word-wrap: break-word;
                        margin-bottom: 5px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    img {
                        margin-right:12px;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                    }

                    div{
                        div{
                            font-weight: bold;
                        }
                    }
                }

                &.unread {
                    a {
                        background-color: #edf2fa;
                        &:hover {
                            color: $dark;
                        }
                    }
                }
            }

            .seeAll{
                text-align:center;
                font-weight: bold;
                color: #365899;

                &:hover{
                    text-decoration: underline;
                }
            }

        }
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
    .navbar-collapse.in{
        overflow-y: auto !important;
    }
    .navbar .navbar-nav .dropdown-menu.dropdown-menu-center{
        width: 190px;
        left: 10px;
    }
  }
