.client {

    .date-range {
        margin: 20px 0px;
    }

    // .month-picker {
    //     cursor: pointer;
    //     padding: 0px 5px 0px 5px;
    //     border-radius: 5px;
    //     overflow: hidden;
    //     //border: 1px solid #e0e0e0;
    //     border-top: 10px solid $main-color;
    //     @include box-shadow(0px, 1px, 1px, rgba(0,0,0,0.5))

    //     .month {
    //         font-size: 1.3em;
    //         font-weight: bold;
    //         line-height: 0.8em;
    //         margin-top: 5px;
    //     }

    //     .year {
    //         font-size: 0.8em;
    //         color: $grey;
    //     }

    //     &.selected, &:hover {
    //         border-top: 10px solid material-color('deep-orange', '800');
    //         background-color: #fffcf7;
    //     }
    // }

    // .tooth-chart{
    //     width:100%;
    // }

    #Spots{
        polygon, path{
            -webkit-transition:fill .25s;
            transition:fill .25s;
        }

        polygon:hover, polygon:active, path:hover, path:active{
            fill:#dddddd !important;
        }
    }

    .entry-filters {
        width:100%;

        .month-picker {
            cursor: pointer;
            padding: 0px 5px 0px 5px;
            //border-radius: 5px;
            overflow: hidden;
            border-top: 10px solid #CFCFCF;
            min-height: 32px;
            display:inline-grid;
            width: 25px;
            background: #ebecf5;
            //@include box-shadow(0px, 1px, 1px, rgba(0,0,0,0.5));

            .month {
                font-size: 1.3em;
                font-weight: bold;
                line-height: 0.8em;
                margin-top: 5px;
            }

            .year {
                font-size: 0.8em;
                color: $grey;
            }

            .day {
                font-size: 0.8em;
                font-weight: bold;
                text-align: center;
            }

            &:hover {
                border: 1px solid #a0a0a0 !important;
                border-top: 10px solid #a0a0a0 !important;
                background-color: #a0a0a0 !important;
            }

            &.current {
                border-top: 10px solid #3D8AE1;
                background-color: #1565C0;
                .day {
                    color: #fff;
                }
            }

            &.selected {
                border-top: 10px solid #424242;
                background-color: #424242;
                .day {
                    color: #fff;
                }
            }

            &.hover {
                border-top: 10px solid #E53935;
                background: #FFEBEE;
            }

            &.success {
                border-top: 10px solid #5DA709;
                background: #F1F8E9;
            }

            &.warning {
                border-top: 10px solid #ffbc40;
                background: #fff1d6;
            }
        }

        .btn-group {
            margin-right: 10px;
            //padding: 3px;
            @include round(5px);
            margin-top:15px;

            label {
                position: absolute;
                top: 0px;
                left: 0px;
                font-weight: normal;
                font-size: 10px;
                margin-top: -15px;
                margin-left:3px;
            }

            .btn {
                border: none;

                padding:6px 10px;
                background: transparent;
                color: $dark;

                &:active {
                    box-shadow: none;
                }

                &:hover {
                    color: $dark;
                }

                &.active {
                    @include round(5px);
                    color: $dark;
                    box-shadow: none;
                    background: $light;

                    background: $main-color;
                    color: $light;
                }
            }
        }

        //padding: 5px;
    }
}
