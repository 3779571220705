
.background-thumbnail-show-modal{
    height: auto;
    width: 280px;
	// max-height: 350px;
	margin-left:-260px;
	margin-top: -500px;
	background:#FFFFFF;
	border-radius: 15px;
	box-shadow: 0 0 1em #808080;
    
}

.thumbnail-modal{
    padding:10px;
    width: 280px;
    height: auto;
    overflow: hidden;
}

.thumbnail-modal img{
    width: 90%;
    border-radius: 5px;
}

@media (max-width: 900px) {
    .background-thumbnail-show-modal {
      display: none;
    }
}

