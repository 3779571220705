.modal-backdrop {
    background-color: $dark;

    &.in {
        opacity: 0.8;
    }
}

.modal-header {
    border: none;
    h4 {
        margin: 0px;
    }
    .pull-left {
        margin-top: 2px;
        font-size: 14px;
    }
}

.modal-body {
    &.no-padding-bottom {
        padding-top: 0;
        padding-bottom: 0;
    }

    .table {
        margin: 0;
    }

    hr {
        margin: 10px -15px;
    }
}

.modal {

    .modal-dialog {
        .modal-content {
            -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.2);
            box-shadow: 0 5px 15px rgba(0,0,0,0.2);
            border: none;

            p {
                margin: 0;
            }

            &.avatar-modal {
                background: #F2F2F2;

                .cropArea {
                    overflow: hidden;
                    width: 450px;
                    height: 450px;
                    margin: 0 auto;
                }
            }
        }

        .modal-body {
            h4 {
                margin: 20px 0;
            }
            .btn-upload{
              border-color: #337ab7;
              color: #337ab7;
              padding: 35px;
              width: 97%;
              border-style: dashed;
              border-radius: 10px;
              margin: auto;
            }
        }

        .modal-footer {
            display: flex;
            justify-content: space-around;
            padding: 0;

            button {
                width: 100%;
                @include round(0);

                &:first-child {
                    @include round-corner('bottom-left', 6px);
                }

                &:last-child {
                    @include round-corner('bottom-right', 6px);
                }

                &.btn-link {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .button-duda {
                background-color: #2B9994;
                color: #FFFFFF;
            }
        }
    }
}

.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
}

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width: inherit;
    height: inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
    background-color: #fff;
    border: none;
    border-radius: 6px;
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.05);
}

.modal-content-scroll{
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;    
}
.modal-content-small-scroll{
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;    
}

.table-scroll{
    margin-bottom: 0;
    max-width: none;
}

.scroll-inner {
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparentize(#ccc, 0.7);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparentize(#ccc, 0.5);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
}

