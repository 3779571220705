.signup {

    .header {
        p {
            font-size: 1.5em;
            font-weight: lighter;
            color: $grey;
            &.lead {
                font-size: 2em;
                font-weight: bold;
                color: $accent-color;
                margin: 0px;
            }
        }
    }
}
