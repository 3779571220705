.cke_button_label{
  &.cke_button__params_docs_label{ display:inline !important }
  &.cke_button__params_name_label{ display:inline !important }
  &.cke_button__params_address_label{ display:inline !important }
}

.cke_button_icon{
  &.cke_button__params_docs_icon{ display:none !important }
  &.cke_button__params_name_icon{ display:none !important }
  &.cke_button__params_address_icon{ display:none !important }
}
