.financial_entry {

    .widget {
        padding: 20px;
        border-bottom: 1px solid #f1f1f1;

        &:last-child {
            border-bottom: none;
        }
    }

    .tab-content {
        padding-top: 0px;
    }

    .alert {
        a {
            cursor: pointer;
            color: inherit;
        }
    }

    .action {
        font-weight: bold;
        color: #9FB1C6;
    }

    .nav-tabs {
        margin-bottom: 1px;
    }

    .entry-back {
        background-color: #f6f7fc;
    }

    .entry-body {
        padding: 15px;
        overflow-y: auto;
        height: 100vh;
        padding-bottom: 150px;
    }

    .entries {
        background-color: $light;
        //min-height: 80%;
        @include round(0px 10px 10px 10px);
    }

    .entry-filters {
        width:100%;

        .month-picker {
            cursor: pointer;
            padding: 0px 5px 0px 5px;
            //border-radius: 5px;
            overflow: hidden;
            border-top: 10px solid #CFCFCF;
            min-height: 32px;
            display:inline-grid;
            width: 25px;
            background: #ebecf5;
            //@include box-shadow(0px, 1px, 1px, rgba(0,0,0,0.5));

            .month {
                font-size: 1.3em;
                font-weight: bold;
                line-height: 0.8em;
                margin-top: 5px;
            }

            .year {
                font-size: 0.8em;
                color: $grey;
            }

            .day {
                font-size: 0.8em;
                font-weight: bold;
                text-align: center;
            }

            &:hover {
                border: 1px solid #a0a0a0 !important;
                border-top: 10px solid #a0a0a0 !important;
                background-color: #a0a0a0 !important;
            }

            &.current {
                border-top: 10px solid #3D8AE1;
                background-color: #1565C0;
                .day {
                    color: #fff;
                }
            }

            &.selected {
                border-top: 10px solid #424242;
                background-color: #424242;
                .day {
                    color: #fff;
                }
            }

            &.hover {
                border-top: 10px solid #E53935;
                background: #FFEBEE;
            }

            &.success {
                border-top: 10px solid #5DA709;
                background: #F1F8E9;
            }

            &.warning {
                border-top: 10px solid #ffbc40;
                background: #fff1d6;
            }
        }

        .btn-group {
            margin-right: 10px;
            //padding: 3px;
            @include round(5px);
            margin-top:15px;

            label {
                position: absolute;
                top: 0px;
                left: 0px;
                font-weight: normal;
                font-size: 10px;
                margin-top: -15px;
                margin-left:3px;
            }

            .btn {
                border: none;

                padding:6px 10px;
                background: transparent;
                color: $dark;

                &:active {
                    box-shadow: none;
                }

                &:hover {
                    color: $dark;
                }

                &.active {
                    @include round(5px);
                    color: $dark;
                    box-shadow: none;
                    background: $light;

                    background: $main-color;
                    color: $light;
                }
            }
        }

        //padding: 5px;
    }

    .debit {
        .progress-bar {
            background-color: $red;
        }
        .btn-entry {
            border-color: $red;
            color: $red;
        }
        .btn-entry:active {
            background-color: $light-red;
        }
    }

    .credit {
        .progress-bar {
            background-color: $green;
        }
        .btn-entry {
            border-color: $green;
            color: $green;
        }
        .btn-entry:active {
            background-color: $light-green;
        }
    }

    .nav-tabs {
        .credit.active a {
            color: $green;
        }

        .debit.active a {
            color: $red;
        }
    }

    .bank {
        .btn-group {
            > .btn:first-child {
                width: 100%;
                border: 0px;
                padding: 10px;
            }
        }

        .account-info {
            text-align: right;
            margin-right: 15px;
            p {
                margin: 0px;
            }

            strong {
                font-size: 1.3em;
            }
        }

        i.icon {
            font-size: 1.5em;
            color: #ADB1B5;
        }

        .dropdown-menu {
            padding: 0px;
            width: 100%;

            li a {
                position: relative;
                padding: 15px;

                i {
                    position:relative;
                    top: inherit;
                    left: inherit;
                    text-align:inherit;
                    width: inherit;
                }
            }
        }

    }
    .progress {
        margin-bottom: 0px;
        @include round(20px);
        height: 10px;
        margin: 5px 0px;
        box-shadow: none;

        > .progress {
            margin: 0px;
        }
    }

    .form-entry {
        .form-control {
            border-width: 1px;
            border-color: #CDD6DE;
            padding: 5px;
        }

        i {
            display: inline;
            font-size: 1em;
        }
    }

    .btn-add-entry {
        text-align: center;
        color: $light;
        min-width: 30px;
        max-width: 30px;
        text-align: center;
        padding: 5px 0px;

        i{
            margin: 0px;
            padding: 0px;
        }
    }

    .btn-category {
        position: relative;
        display: inline-block;
        vertical-align: middle;

        .btn-plus {
            max-width: 37px !important;
            min-width: 0 !important;
            float:right;
        }

        .multi {
            max-width: 97px;
            position: relative;
            float: left;
        }
    }

    .ats-switch {
        zoom: 60%;
    }

    .caret {
        position: initial;
    }

    .btn-entry {
        display: inline-block;
        width: 60%;
        border-radius: 15px;
        border-style: dashed;
        border-width: 1px;
        background-color: rgba(0, 0, 0, 0);
        box-shadow: none;
    }

    .switch-painel{
        .btn-group-switch-painel{

            width: 100%;

            .btn-switch-painel-left {
                width: 50%;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                border-style: dashed;
                border-width: 1px;
                background-color: rgba(0, 0, 0, 0);
                box-shadow: none;
                border-color: $main-color;
                color: $main-color;

                &.active {
                    background-color: $main-color;
                    color: #FFFFFF;
                }
            }

            .btn-switch-painel-right {
                width: 50%;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                border-style: dashed;
                border-width: 1px;
                background-color: rgba(0, 0, 0, 0);
                box-shadow: none;
                border-color: $main-color;
                color: $main-color;

                &.active {
                    background-color: $main-color;
                    color: #FFFFFF;
                }
            }
        }
    }

    .nav-tabs {
        border: none !important;

        ul {
            background-color: #dadee4;
        }

        li {
            a {
                background-color: #f8f8f8;
                border: none !important;
                color: inherit;
            }
        }

        li.active {
            a {
                background-color: $light;
                font-weight: bold;
            }
        }
    }

    .financial-table {
        margin: 0px;
        #financial-table-head {
            > th {
                border-left: 1px solid $border;
                &:first-child, &:last-child {
                    border-left: 0px;
                }
            }
        }

        #financial-table-body {

            .entry-options {
                padding: 0px;
                box-shadow: none;
                min-width: 30px;
                i {
                    font-size: 1.5em;
                    font-weight: bold;
                }
            }

            > td {
                padding: 5px;
                border-bottom: 2px solid $border;
                max-width: 180px;
            }
        }
    }


    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    /* Add animation to "page content" */
    .animate-bottom {
        position: relative;
        -webkit-animation-name: animatebottom;
        -webkit-animation-duration: 1s;
        animation-name: animatebottom;
        animation-duration: 1s
    }

    @-webkit-keyframes animatebottom {
        from { bottom:-100px; opacity:0 }
        to { bottom:0px; opacity:1 }
    }

    @keyframes animatebottom {
        from{ bottom:-100px; opacity:0 }
        to{ bottom:0; opacity:1 }
    }
}
