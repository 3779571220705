.background-list {
	z-index: 2;
	max-height: 350px;
	background: #FFFFFF;
	border-radius: 15px;
	box-shadow: 0 0 1em #808080;
	overflow: auto;
	position: absolute;
	right: -215px;
	top: 10px;
	margin-top: 50px;

	h5 {
		color: #57A300; 
	}
	ul {
		padding: 7px;
		list-style: none; 
	}
	span {
		color: #808080;
		font-size: 0.8em; 
	}
	b {
		color: #808080;
		font-size: 0.8em; 
	}
	.total {
		color: #337ab7;
		font-size: 1.1em;
		padding-top: 100px; 
	}
	.total-quantity{
		color:#808080;
		font-size: 1.1em;
		padding-top:90px;
	}	

}
#small-screen{
	display: none;	
}

@media only screen and (max-width: 1280px) {
	.background-list{
		z-index: 4;
		max-height: 350px;
		background: #FFFFFF;
		border-radius: 15px;
		box-shadow: 0 0 1em #808080;
		overflow: auto;
		position: absolute;
		right: 80px;
		top: 10px;
		margin-top: 35px;

		h5 {
			color: #57A300;
		}
			
		ul {
			padding: 7px;
			list-style: none;
		}
			
		span {
			color: #808080;
			font-size: 0.8em; 
		}
			
		b {
			color: #808080;
			font-size: 0.8em;
		}
		
		.total {
			color: #337ab7;
			font-size: 1.1em;
			padding-top: 100px;
		}
	}
	#small-screen{
		display: block;	
	}
	.button-small-screen{
		background: #57A300; 
		width: 45px; 
		height: 45px; 
		margin-left: calc(100% - 100px);
		margin-top: -45px; 
		border-radius:50%; 
		box-shadow: 0 0 1em #f5f0f0; 
		position: relative; 
		float: left;
	}
		
	.icon-button{
		position: absolute; 
		top: 50%; 
		left: 50%; 
		transform: 
		translate(-50%,-50%); 
		color:#ffffff;
	}
	  
}

.obs-enrollment{
	z-index: 2;
	padding: 20px;
	width: 340px;
	background: #FFFFFF;
	border-radius: 15px;
	box-shadow: 0 0 1em #808080;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	.button-obs{
		background: #d9534f; 
		width: 25px; 
		height: 25px; 
		margin-left: 297px;
		margin-top: -30px; 
		border-radius:50%; 
		box-shadow: 0 0 1em #f5f0f0; 
		position: relative; 
		float: left;
	}

	.icon-button-obs{
		position: absolute; 
		top: 50%; 
		left: 50%; 
		transform: 
		translate(-50%,-50%); 
		color:#ffffff;
	}
	
}

  