
.multiselect-parent {
    width: 100%;
}

.or {
    text-align: center;
    margin: 20px 0px;
    position: relative;

    &:before, &:after {
        display: block;
        position: absolute;
        top: 10px;
        width: 45%;
        content: " ";
        height: 1px;
        background-color: lighten($dark, 50%);
    }

    &:before {
        left: 0px;
    }

    &:after {
        right: 0px;
    }
}

.full-height {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex {
    display: flex;

    &.flex-end {
        align-items: flex-end;
    }

    &.vertical-align {
        align-items: center;
    }

    &.align-center {
        justify-content: center;
        align-items: center;
    }

    .align-left {
        align-self: flex-start;
    }

    .align-right {
        align-self: flex-end;
    }

    &.flex-justified > * {

        flex-grow: 1;
        padding: 0px 10px;
        margin: 0px;

        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-right: 0px;
        }
    }

}

.invert {
    color: $light;
    &.orange {
        @include gradient($main-color,$second-color);
    }
}

h1,h2,h3,h4,h5,h6 {
    .btn {
        font-family: 'Circular',Helvetica,Arial,sans-serif;
        line-height: 100%;
        margin-top: -5px;
        margin-left: 20px;
    }
}

.label {
    font-weight: normal;
}

.angular-google-map-container {
    margin-top: 28px;
    height: 385px;
    @include round(5px);
}

.ng-toast__message {
    .alert-success {
        background-color: $green;
        border: none;
        @include box-shadow(0px, 10px, 20px, rgba(0,0,0,0.2));
        margin-top: 55px;
        color: $light;
    }
}

.table-hover {
    tr {
        cursor: pointer;
    }
}

.dropdown-menu {
    > li {
        >a {
            cursor: pointer;
        }
    }
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
.ats-switch
{
    outline:0px !important;
    //-webkit-appearance:none;
}

.uib-datepicker-popup {
    button {
        border: none;
    }
}

.pagination {
    margin: 0px;
}

.ng-table th.filter .input-filter {
    border: none;
    background: #f8f8f8;
}

.no-focus:focus {
  outline: none;
}

.ats-switch .knob {
    z-index: 1;
}

.clickable {
    cursor: pointer;
}


.backgroundLoader {
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: 10;
}

.loader {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid rgba(0,0,0,0.5);
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 300ms linear infinite;
    animation: spin 300ms linear infinite;
    z-index: 11;
}

.scrollable {
    overflow: auto !important;
    height: 500px;
}

.text-light {
    color: $light !important;
}

.thumbnail{
    .deleteButton {
        position: absolute;
        color: red;
        top: 10px;
        right: 20px;
        border: none;

        i {
            height: 10px;
            width: 10px;
        }
    }
}

.legend{
    margin-top: -5px !important;
    font-size: 0.8em !important;
    margin-bottom: -20px !important;
    // float: left;
}
