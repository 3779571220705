
.ats-switch {

    min-width: 60px;
    width: 60px;
    height: 28px;
    border: none;
    overflow: hidden;
    border-radius: 60px;

    &:hover {
        border-color:none;
        box-shadow: none;
    }

    .switch-animate {

        overflow: hidden;
        height: 60px;
        max-height: 60px;
        width: 60px;
        max-width: 60px;

        .switch-left {
            transition: all 0.2s !important;
            -o-transition: all 0.2s !important;
            -moz-transition: all 0.2s !important;
            -webkit-transition: all 0.2s !important;
            width: 60px;
            height: 28px;
        }

        .switch-right {
            display: none;
        }

        .knob {
            transition: all 0.2s !important;
            -o-transition: all 0.2s !important;
            -moz-transition: all 0.2s !important;
            -webkit-transition: all 0.2s !important;
            @include round(50%);
            position: absolute;
            left: 34px;
            top: 4px;
            width: 22px;
            height: 22px;
            min-height: 22px;
        }

        &.switch-off {
            left: 0%;
            .knob {
                left: 34px;
            }

            .switch-left {
                background-color: #ccc;
            }
        }

        &.switch-off {
            left: 0%;
            .knob {
                left: 4px;
            }
        }
    }
}
