.home {

    .panel-heading{
        text-align: center;
        font-weight: bold;
    }

    .panel-body {

        max-height:71vh;
        overflow-y: auto;
        padding:15px;

        //style scrollbar chrome
        &::-webkit-scrollbar {
            width: 1em;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #eee;
            outline: 1px solid slategrey;
        }

        .alert {
            margin: 0px;
        }

        a {
            display: block;
            text-decoration: none;
            background-color:#f1f1f1;
            color: #4a4a4a;
            padding: 15px;
            margin-bottom:15px;

            &:last-child {
                margin-bottom: 0px;
            }

            @include round(5px);

            &:hover {
                background-color: $main-color;
                color: $light;
            }
        }
    }
}
