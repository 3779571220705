
.btn {
    font-size: 14px;
    line-height: 20px;
    //min-width: 68px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;


    &.btn-link{

        &:hover{
            color: $main-color;
            text-decoration: underline;
        }

        &:active:focus{
            color: $main-color;
            background: transparent;
            border-color: transparent;
        }
    }

    &.boleto_vencendo{
        background-color: $third-color;
        color           : white;
        line-height     : 25px ;
        text-transform  : inherit;
        font-weight     : bold;
        font-size       : 16px;
        width           : 100%;
        margin-bottom   : 15px;
        border          : none;
        i{
            font-size: 17px;
        }
    }

    i {
        font-size: 13px;
    }
}

.btn-outline {

    background-color: transparent;
    color: inherit;
    transition: none;
    font-weight: bold;

    &.btn-primary:hover,
    &.btn-success:hover,
    &.btn-info:hover,
    &.btn-warning:hover,
    &.btn-danger:hover,
    &.btn-primary:focus,
    &.btn-success:focus,
    &.btn-info:focus,
    &.btn-warning:focus,
    &.btn-danger:focus, {
        color: #fff;
    }

    &.btn-primary {
        color: #428bca;

        &.disabled, &[disabled]{
            background: transparent;
            color: #428bca;
        }
    }

    &.btn-success {
        color: #5cb85c;

        &.disabled, &[disabled]{
            background: transparent;
            color: #5cb85c;
        }
    }

    &.btn-info {
        color: #5bc0de;

        &.disabled, &[disabled]{
            background: transparent;
            color: #5bc0de;
        }
    }

    &.btn-warning {
        color: #f0ad4e;

        &.disabled, &[disabled]{
            background: transparent;
            color: #f0ad4e;
        }
    }

    &.btn-danger {
        color: #d9534f;

        &.disabled, &[disabled]{
            background: transparent;
            color: #d9534f;
        }
    }

}

.input-group-btn {

    .btn {
        line-height: 20px;
    }


    .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
        opacity: 1;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
    }
}

.btn-table-add {
    display: inline-block;
    width: 60%;
    border-radius: 15px;
    border-style: dashed;
    border-width: 1px;
    border-color: #5cb85c;
    color: #5cb85c;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
}
