
.form-group {
    label {
        font-size: 0.9em;
        font-weight: normal;;
    }

    .btn {
        line-height: 20px;
    }

    .dropdown-multiselect {
        .btn[disabled] {
            border-color: transparent;
            background-color: #f5f5f5;
            box-shadow: none;
            opacity: 1;
        }
    }

    &.has-error {
        .btn {
            border-color: #a94442;
            color: #a94442;
        }
    }

    p {
        background: #f5f5f5;
        @include round(5px);
        padding: 6px 10px;
        overflow: hidden;
    }
}

.input-group {

    &.multiple-input-group  {
        input[type="select"] {
            @include round(0px);
        }
    }

    &.ddd-wrapper {
        width: 100%;

        .form-control {
            width:75%;
        }

        .ddd{
            width:25%;
        }
    }


}

.form-control {
    transition: none;
    box-shadow: none;

    &:focus {
        border-color: #539CDC;
        box-shadow: none;
    }

    &.has-error {
        border-color: $light-red !important;
        color: $light-red !important;
    }
}

.form-control-input {
    transition: none;
    box-shadow: none;
    width: 120px;
    display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;

    &:focus {
        border-color: #539CDC;
        box-shadow: none;
    }

    &.has-error {
        border-color: $light-red !important;
        color: $light-red !important;
    }
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    border-color: transparent;
    background-color: #f5f5f5;
    box-shadow: none;
    opacity: 1;
}

.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: left;
    font-weight: 600;
}

input[type="radio"], input[type="checkbox"] {
    margin: 0px 0 0;
}

.form-inline .radio {
    margin-right: 10px;

    input[type="radio"] {
        margin-right: 5px;
    }
}
