@import 'sass-material-colors-map';
/**
 * Retrieves a color value from the $material-colors Sass map
 * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/
 *
 * @function
 * @param {string} color-name             - quoted, lower-case, dasherized color
 *                                          name (e.g. 'pink', 'amber')
 * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.
 *                                          '200', 'a100')
 */
@function material-color($color-name, $color-variant: '500') {
  $color: map-get(map-get($material-colors, $color-name),$color-variant);
  @if $color {
    @return $color;
  } @else {
    // Libsass still doesn't seem to support @error
    @warn "=> ERROR: COLOR NOT FOUND! <= | Your $color-name, $color-variant combination did not match any of the values in the $material-colors map.";
  }
}
