/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
    .btn-group {
        margin: 5px 0px;
        width: 100%;
    }

    h1.title {
        width: 100%;
        margin: 0px;
        .label {
            display: block;
            width: 100%;
            margin: 5px 0px 0px 0px;
        }
    }
}
