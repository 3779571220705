.content {
    position: relative;

    .actions {
        width: 100%;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
        margin: 15px 0px;

        .btn {
            line-height: 14px;
        }
    }

    .table {
        margin: 15px 0px;
    }

    .container {
        position: relative;

        .padding {
            padding: 100px 0px;
        }

        .nav-tabs {
            margin-top: 20px;
        }
    }

    /* start 'enter' transition */
    &.ng-enter {
        /* transition on enter for .5s */
        transition: .8s cubic-bezier(.39,.31,.59,.96) all !important;

        /* start with opacity 0 (invisible) */
        opacity: 0;
        margin-top: 0px;
        min-height: 0px;
    }

    /* end 'enter' transition */
    &.ng-enter-active {
        /* end with opacity 1 (fade in) */
        opacity: 1;
        margin-top: 0px;
        min-height: auto;
    }
}

.no-margin {
    margin:0px;
}

.padding-top{
    padding-top: 10px;
}

.padding-bottom{
    padding-bottom: 10px;
}

.padding-right{
    padding-right: 10px;
}

.padding-left{
    padding-top: 10px;
}
