
.footer {
    position: relative;
    background: $dark;
    color: lighten($dark, 30%);
    line-height: 40px;
    bottom: 0;
    width: 100%;
    font-size: 0.8em;

    padding: 2em;

    a {
        color: lighten($dark,30%);
        line-height: 40px;
        font-size: 0.8em;
        margin: 0px 10px;

        &:hover {
            color: $light;
            text-decoration: none;
        }

        &.no-margin {
            margin: 0px;
        }
    }

    img {
        height:30px;
        margin-top: 4px;
        margin-left: 5px;
    }


    .site {
        font-size: 0.5em;
    }

    .site a {
        font-size: 13px;
        font-weight: bold;
        align-items: flex-end;

    }

    .cards {
        position: relative;
        align-items: center;
    }

    .cards p {
        font-weight: bold;
        font-size: 18px;
    }

    .cards img {
        position: relative;
        margin: 5px;
        width: 40px;
        height: 20px;
        margin-left: 25px;
        margin-top: 20px;
    }

    .mobile-hidden p a {
        position: relative;
        margin-right: -70px;
    }

    #promo p {
        font-weight: lighter;
    }

    #promo .contact-name input {
        font-weight: lighter;
        height: 40px;
        border-radius: 10px;
        border-style: solid;
        border-color: #948b8b;
        background: #FFFFFF;
    }
    #promo .contact-email input {
        font-weight: lighter;
        height: 40px;
        border-radius: 10px;
        border-style: solid;
        border-color: #948b8b;
        background: #FFFFFF;
    }

    .contact-button {
        position: relative;
        padding: 10px;
        margin-bottom: 20px;

    }

    .siteseg {
        display: block;
        align-items: center;
        margin-top: 0px;

    }

    .siteseg img {
        width: 80px;
        height: auto;
    }

    .float{
        position:fixed;
        width:60px;
        height:60px;
        bottom:40px;
        right:60px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
      font-size:30px;
        box-shadow: 2px 2px 3px #999;
      z-index:100;
    }
    
    .my-float{
        margin-top:5px;
    }
}
