@font-face
{
  font-family: 'Circular';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/circular/CircularStd-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/circular/CircularStd-Light.woff2') format('woff2'), url('/fonts/circular/CircularStd-Light.woff') format('woff'), url('/fonts/circular/CircularStd-Light.ttf') format('truetype');
}

@font-face
{
    font-family: 'Circular';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/circular/CircularStd-Medium.eot?#iefix') format('embedded-opentype'), url('/fonts/circular/CircularStd-Medium.woff2') format('woff2'), url('/fonts/circular/CircularStd-Medium.woff') format('woff'), url('/fonts/circular/CircularStd-Medium.ttf') format('truetype');
}

h1,h2,h3,h4,h5,h6 {
  font-family : 'Circular', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

body, input, textarea, select, button {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
}

h1 {
  font-size: 1.6em;

  &.title {
    display: inline-block;
    margin: 0px 0px 15px 0px;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-warring {
    color: $yellow;
}

a {
  &:hover {
      text-decoration: none;
  }
}

.star-fill {
  cursor: pointer;
  //color: material-color('amber');
}

.glyphicon {
  font: normal normal normal 14px/1 FontAwesome !important;

  &.glyphicon-resize-full::before {
    content: "\f065";
  }

  &.glyphicon-resize-small::before {
    content: "\f066";
  }

  &.glyphicon-remove::before {
    content: "\f00d";
  }

  &.glyphicon-chevron-left::before {
    content: "\f053";
  }

  &.glyphicon-chevron-right::before {
    content: "\f054";
  }

  &.glyphicon-ok::before {
    content: "\f00c";
  }
}

.mute {
    color: #C9C9C9;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-active {
    color: $blue;
}

.text-success {
    color: #57A300;
}

.text-warning {
    color: #FFB732;
}

.text-danger {
    color: $red;
}

.cke_button_label{
  &.cke_button__params_docs_label{ display:inline !important }
  &.cke_button__params_name_label{ display:inline !important }
}

.cke_button_icon{
  &.cke_button__params_docs_icon{ display:none !important }
  &.cke_button__params_name_icon{ display:none !important }
}
