/*
 *  Mixin's
 */
@mixin gradient($from, $to) {
  background: $from;
  background: -moz-linear-gradient(top,  $from 0%, $to 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
  background: -webkit-linear-gradient(top,  $from 0%,$to 100%);
  background: -o-linear-gradient(top,  $from 0%,$to 100%);
  background: -ms-linear-gradient(top,  $from 0%,$to 100%);
  background: linear-gradient(to bottom,  $from 0%,$to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 );
}

@mixin round($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin round-corner($corner, $radius: 0.5em){
  -moz-border-#{$corner}-radius:    $radius ; // firefox
  -webkit-border-#{$corner}-radius: $radius;  // safari/chrome
  border-#{$corner}-radius:         $radius;  // CSS3, works in IE9
}

@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
  -webkit-box-shadow:$top $left $blur $color #{$inset};
  -moz-box-shadow:$top $left $blur $color #{$inset};
  box-shadow:$top $left $blur $color #{$inset};
}

@mixin text-shadow($horizontal, $vertical, $blur, $color){
  text-shadow: $horizontal $vertical $blur $color;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
