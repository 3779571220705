.onboarding {
	input {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;

		border-radius: 0px;

		border-bottom: 3px solid #888888;
	}

	select {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;

		border-radius: 0px;

		border-bottom: 3px solid #888888;
	}

	h1 {
		color: #888888;
	}

	.imgLogoRegister {
		height: 80px;
	}

	.vertical-center {
		min-height: 50%;
		min-height: 50vh;

		align-items: center;
		padding: 15px;
	}

}