
.dropdown-menu {

    top: auto !important;

    > li > a {
        position: relative;
        padding: 3px 20px 3px 30px;

        i {
            position: absolute;
            left: 8px;
            top: 8px;
            text-align: center;
            width: 20px;
        }

        span {

        }
    }

    .divider {
        margin: 5px 0px;
    }
}

.multiselect-parent {
    .btn {
        line-height: 20px;
        text-align: left;
        padding-right: 25px;

        .caret {
            position: absolute;
            top: 14px;
            right: 13px;
        }
    }
}

.dropdown-menu-form {

    li {
        position: relative;

        .label {
            position: absolute;
            right: 10px;
            top: 5px;
            height: 16px;
        }

        &.dropdown-header {
            padding: 0px;
            a {
                display: block;
                padding: 3px 20px;
                font-size: 14px;
                line-height: 1.42857143;
                color: $grey;
                outline: none;
                cursor: default;
                background-color: #f1efef;
                &:hover {
                    color: #999;
                }
            }
        }
    }

    > li:first-child {
        a {
            display: inline-block;
            //width: 46%;
            text-align: center;
            border-radius: 6px;
            line-height: 30px;
            margin: 0px 0px 0px 5px;

            .fa {
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }
    }

    > li:last-child {
        padding: 5px 5px 0px 5px;
    }

    .dropdown-header {
        padding: 3px 2px 0px 2px;
        font-size: 12px;
        line-height: 1.42857143;
        color: $dark;
        font-weight: bold;
        white-space: nowrap;

        &:hover {
            background-color: inherit;
        }

        .searchField {
            border: none;
        }
    }

    ul {
        overflow-x: hidden;
        list-style: none;
        padding: 0px;

        > li {
            &:hover, &.active {
                background-color: $main-color;
                a {
                    color: $light;
                }
            }

            > a {
                cursor: pointer;
                display: block;
                padding: 3px 20px;
                clear: both;
                font-weight: 400;
                line-height: 1.42857143;
                color: #333;
                white-space: nowrap;
                text-decoration: none;
            }
        }
    }

    .btn-ok {
        position: absolute;
        bottom: 0px;
        left: 10px;
        right: 10px;
    }
}

.angucomplete-dropdown-visible {
    input {
        position: relative;
        z-index: 999;
        border-bottom: 0px;
        @include round-corner('bottom-left', 0px);
        @include round-corner('bottom-right', 0px);
    }
}

.angucomplete-dropdown {
    @include round-corner('bottom-left', 5px);
    @include round-corner('bottom-right', 5px);
    @include round-corner('top-right', 5px);
    border: 1px solid #CDD6DE;
    box-shadow: 0 10px 24px rgba(25,73,155, .08), 0 4px 13px rgba(25,73,155, .04);
    margin-top: -1px;
    padding: 0px;
    overflow: hidden;
    z-index: 99;

    .augucomplete-list {
        max-height: 300px;
        overflow-y: auto;
        margin-bottom: 40px;
    }

    .angucomplete-row {
        padding: 5px 15px;

        &.angucomplete-selected-row {
            background: #dae1e8;
            .angucomplete-title {
                color: $main-color;
            }
        }

        .angucomplete-title {
            color: $dark;
            font-weight: bold;
        }

        .angucomplete-button-duda {
            background-color: #2B9994;
            color: #FFFFFF;
        }

        .angucomplete-description {
            color: $dark;
            font-weight: lighter;
            font-size: 0.8em;
        }

        &.btn-create-wrap {
            position: absolute;
            bottom: -4px;
            left: 0px;
            width: 100%;
            background: $light;
            padding: 5px;

            .btn {
                width: 100%;
                color: $light;
            }
        }
    }


}

.angucomplete-holder {
    .form-control.ng-invalid {
        border-color: $light-red !important;
    }
}
