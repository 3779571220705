.miniChart {

    cursor: pointer;
    padding: 0px;
    @include round(5px);
    overflow: hidden;
    border: 1px solid #bbb8b8;

    &.active, &:hover {
        border: 1px solid #5180c7;
        background-color: #e9f2ff;
    }

    p {
        padding: 0px;
        text-align: center;
    }

    h3 {
        margin: 20px 10px 0px 10px;
        font-weight: bold;
        text-align: center;
    }

    .highcharts-container {
        margin-left: -17px;
        margin-bottom: -10px;
        margin-top: -15px;
    }
}

.miniChart-disabled {

    cursor: not-allowed;
    padding: 0px;
    @include round(5px);
    overflow: hidden;
    border: 1px solid #f1f1f1;
    background-color: #f5f0f0;

    &.active, &:hover {
        border: 1px solid #ccc;
        background-color: #ccc;
    }

    p {
        padding: 0px;
        text-align: center;
    }

    h3 {
        margin: 20px 10px 0px 10px;
        font-weight: bold;
        text-align: center;
    }

    .highcharts-container {
        margin-left: -17px;
        margin-bottom: -10px;
        margin-top: -15px;
    }
}

.highcharts-container {
    margin: 0;
}

.highcharts-credits {
    display: none;
}

.with-border {
    .highcharts-container {
        border: 1px solid #f1f1f1;
        @include round(5px);
    }
}

.customHighCharts {
    width: 100%;
}
