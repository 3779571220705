
@mixin reportNavColor($color, $highlight){
    > li > a:hover, > li.open, li.active > a{
        color: $color;
    }

    > li > .dropdown-menu > li > a:hover {
        background-color: $color;
        color: $highlight;
    }
}

.reports {

    td {
        font-size: 0.9em;
    }

    .filters {
        display: flex;
        padding: 5px !important;

        .form-group {
            padding: 5px !important;
            margin-right: 5px;
            &:last-child {
                margin-right: 0px;
                margin-left: auto;
            }
        }
    }

    .shortcuts {

        border-bottom: 1px solid $border;
        margin-bottom: 15px;

        .container {
            padding-top: 0px;
        }

        .form-group {
            width: 150px;
            display: inline-block;
            margin: 3px 30px 0px 0px;
        }

        .navbar-nav {
            padding-top: 5px;
            > li {

                text-align: center;
                margin-right: 15px;

                > a {
                    background: transparent;
                    margin: 0px;
                    border-radius: 0px;
                    line-height: 20px;
                    font-size: 1em;
                    color: lighten($dark, 30%);
                    padding: 8px 10px;

                    &:hover {
                        background: transparent;
                        color: $dark;
                    }
                }

                &.open {
                    background: $light;
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;

                    > a {
                        color: $dark;
                    }
                }
            }

            @include reportNavColor(material-color('blue', '800'), $light);

            &.grey {
                @include reportNavColor(material-color('grey'), $light);
            }
            &.light_grey {
                @include reportNavColor(material-color('grey', '100'), $dark);
            }

            &.red {
                @include reportNavColor(material-color('red'), $light);
            }
            &.light_red {
                @include reportNavColor(material-color('red', '100'), $light);
            }

            &.black {
                @include reportNavColor(material-color('grey', '900'), $light);
            }

            &.white {
                @include reportNavColor(#fff, $dark);
            }

            &.purple {
                @include reportNavColor(material-color('purple'), $light);
            }
            &.light_purple {
                @include reportNavColor(material-color('purple'), $dark);
            }

            &.green {
                @include reportNavColor(material-color('green'), $light);
            }
            &.light_green {
                @include reportNavColor(material-color('green', '100'), $dark);
            }

            &.yellow {
                @include reportNavColor(material-color('yellow'), $light);
            }
            &.light_yellow {
                @include reportNavColor(material-color('yellow', '100'), $dark);
            }
        }
    }
}
