
.table {
  font-size: 12px;
    &.table-pointer {
        tr{
            cursor: pointer
        }
    }

    td{
        font-family: arial;
    }

    tr.active {
        td {
            background: $main-color !important;
            color: $light;
        }
    }

    tr.empty {
        td {
            height: 0px;
            max-height: 0px;
            padding: 0px;
            margin: 0px;
            border: none;
        }
    }

    tr{
        .btn-actions-address-client{
            width: 75px;
             i{
                 width: 25px;
             }
        }

        .action_button {
            min-width: 0px;
        }
    }

    td.truncate {
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
    }

}

.ng-table th.sortable.sort-desc, .ng-table th.sortable.sort-asc {
    background-color: transparent;
    text-shadow: none;
    color: $red;
}


.rating-table {
    span {
        outline: none;
        zoom: 0.5;
    }

    strong {
        font-family: "Myriad Set Pro", "Lucida Grande", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
        font-weight: bold;
        margin-top: 2px;
    }
}

.tooltip-lg{
    font-size: 14px;
}
