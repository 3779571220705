.register_step {
	input {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;

		border-radius: 0px;

		border-bottom: 3px solid #888888;
	}

	select {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;

		border-radius: 0px;

		border-bottom: 3px solid #888888;
	}

	h1 {
		color: #888888;
	}

	.button-duda {
		box-shadow: 2px 1px 3px #101010;
		background-color: #2B9994;
		color: #FFFFFF;
		font-weight : bold ;
	}

	.imgLogoRegister {
		height: 50px;
	}

	.duda-color {
		color: #2B9994;
	}

	.vertical-center {
		min-height: 50%;
		min-height: 50vh;

		display: flex;
		align-items: center;
	}
}