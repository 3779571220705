.balance {
    > .day {
        cursor: pointer;
        padding-bottom:3px;
        .indicator {
            background: #ccc;
            padding: 2px 15px;
            text-align: center;
            color: #fff;
            font-weight: bold;
            @include round(5px);
            margin-right: 3px;

            .number {
                font-size: 1.5em;
            }

            .text {
                text-transform: uppercase;
                font-size:0.8em;
            }
        }

        .description {
            background: #F2F2F0;
            @include round(5px);
            padding: 3px 15px;
            color: #9E9E9E;
            width: 100%;

            p {
                font-size: 1.2em;
                font-weight: bold;
                text-transform: uppercase;
                font-style: italic;
                letter-spacing: -1px;
                margin: 0px;
                line-height: 40px;
            }

            .operations {
                margin-left: auto;
                p {
                    margin: 0px 15px;
                    font-style: normal;
                    small {
                        font-size:0.5em;
                    }
                }
            }

        }
    }


}
