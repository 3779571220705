@include keyframes(newmessage) {
    0% {
        background-color: #d6dfec;
    }

    49% {
        background-color: #d6dfec;
    }

    50% {
        background-color: #b5c0d2;
    }

    99% {
        background-color: #b5c0d2;
    }

    100% {
        background-color: #d6dfec;
    }
}

@include keyframes(newchat) {
    0% {
        background-color: $light-red;
    }

    49% {
        background-color: $light-red;
    }

    50% {
        background-color: $red;
    }

    99% {
        background-color: $red;
    }

    100% {
        background-color: $light-red;
    }
}

%status {
    padding: 10px 15px 10px 5px;
    font-size: 0.5em;
    color: $grey;

    &.online {
        color: $green;
    }
}

.dock {
    position: fixed;
    height: 35px;
    z-index: 999;
    right: 0;
    bottom: 0;

    .messages-wrap {
        height: 271px;
        width: 250px;
        right: 0;
        bottom: 0;
        left: auto;
        position: absolute;
        background: $light;
        margin: 0 10px;
        @include box-shadow(0px, 5px, 15px, rgba(0,0,0,0.2));
        @include round(5px 5px 0 0);
        overflow: hidden;
        z-index: 999;

        display: flex;
        flex: 3;
        flex-direction: column;

        .search {
            display: block;
            padding: 0px;
            position: relative;
            border-top: 1px solid #ddd;

            label {
                box-sizing: border-box;
                cursor: default;
                display: inline-block;
                font-weight: normal;
                margin: 0;
                position: relative;
                vertical-align: middle;
                width: 100%;

                &:before {
                    background-image: url("https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/T7-56nOj8xk.png");
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: -34px -324px;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    left: 8px;
                    position: absolute;
                    top: 10px;
                    width: 12px;
                }

                input {
                    background: transparent;
                    border: 0;
                    margin: 0;
                    outline: 0;
                    padding: 0;
                    width: 100%;
                    background-color: #f6f7f9;
                    border-radius: 5px;
                    border-style: none;
                    box-sizing: border-box;
                    font-family: 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif';
                    font-size: 14px;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 28px;
                }
            }
        }

        .head {
            display: flex;
            background: #424242;
            color: $light;
            line-height: 30px;
            min-height: 30px;
            max-height: 30px;
            padding: 0 10px;
            cursor: pointer;
            font-weight: bold;
            @include box-shadow(0px, 1px, 2px, rgba(0,0,0,0.2));
            z-index: 99;

            .align-right {
                margin-left: auto;
            }

            a {
                color: $grey;

                &:hover {
                    color: $light;
                }
            }
        }

        .body {
            position: relative;
            background: #f9f9f9;
            height: 100%;
            min-height: 200px;
            overflow-y: scroll;

            .messages {
                list-style-type: none;
                margin: 0;
                padding-left: 0px;
                padding-right: 0px;

                li {
                    width: 100%;


                    &:hover {
                        background: #ddd;
                    }

                    a {
                        position: relative;
                        width: 100%;
                        line-height: 45px;
                        text-decoration: none;
                        color: $dark;

                        display: flex;
                        flex: 1;

                        p {
                            margin: 0;
                            width: 100%;
                        }

                        .status {
                            @extend %status;
                        }

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    img {
                        display: block;
                        height: 30px;
                        width: auto;
                        margin: auto 10px;
                        @include round(50%);
                    }

                    &.divider {
                        font-weight: bold;
                        font-size: 0.7em;
                        padding: 5px 10px;
                    }

                    &.new_message {
                        background: #ff0;

                        -webkit-animation: newmessage 1s infinite;
                        /* Safari 4+ */
                        -moz-animation: newmessage 1s infinite;
                        /* Fx 5+ */
                        -o-animation: newmessage 1s infinite;
                        /* Opera 12+ */
                        animation: newmessage 1s infinite;
                        /* IE 10+, Fx 29+ */
                    }

                    &.new,
                    &.new:hover {
                        background: $light-red !important;

                        -webkit-animation: newchat 1s infinite;
                        /* Safari 4+ */
                        -moz-animation: newchat 1s infinite;
                        /* Fx 5+ */
                        -o-animation: newchat 1s infinite;
                        /* Opera 12+ */
                        animation: newchat 1s infinite;
                        /* IE 10+, Fx 29+ */

                        a,
                        a:hover {
                            color: $light !important;
                            font-weight: bold !important;
                        }
                    }
                }
            }
        }

        &.closed {
            height: 30px;

            .body,
            .footer {
                display: none;
                background: transparent;
                height: 0;
            }
        }
    }

    .chat {
        max-height: 271px;
        width: 250px;
        bottom: 0;
        background: $light;
        margin: 0 10px;
        @include box-shadow(0px, 5px, 15px, rgba(0,0,0,0.2));
        @include round(5px 5px 0 0);
        overflow: hidden;
        flex-direction: column;

        .head {
            display: flex;
            background: $light-grey;
            color: $dark;
            line-height: 30px;
            padding: 0 10px;
            cursor: pointer;
            font-weight: bold;
            @include box-shadow(0px, 1px, 2px, rgba(0,0,0,0.2));
            z-index: 99;

            &.active {
                background: $main-color;
                color: $light;
            }

            &.new-message {
                -webkit-animation: newmessage 1s infinite;
                /* Safari 4+ */
                -moz-animation: newmessage 1s infinite;
                /* Fx 5+ */
                -o-animation: newmessage 1s infinite;
                /* Opera 12+ */
                animation: newmessage 1s infinite;
                /* IE 10+, Fx 29+ */
            }

            .align-right {
                margin-left: auto;
            }

            .status {
                @extend %status;
                padding: 0px 5px 0px 0px;
            }
        }

        .footer {
            position: relative;
            background: #fff;
            display: flex;
            flex: auto 1 1;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0;
            border-top: 1px solid #e2e2e2;
            height: 40px;

            * {
                background: none;
                border: none;
            }

            textarea {
                resize: none;
                width: 100%;
                height: 100%;
                line-height: 40px;
                padding: 0 10px;
                color: $dark;
                font-size: 1.2em;
            }

            i {
                padding: 0px;
                height: 39px;
                line-height: 39px;
                display: block;
                width: 35px;
                cursor: pointer;
                text-align: center;
            }
        }

        .body {
            overflow-y: scroll;
            flex: 1;
            height: 200px;
            background: #f1efec;

            .messages {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    width: 100%;

                    a {
                        position: relative;
                        width: 100%;
                        line-height: 45px;
                        text-decoration: none;
                        color: $dark;

                        p {
                            margin: 0;
                        }

                        &:hover {
                            background: #ddd;
                            text-decoration: none;
                        }
                    }

                    img {
                        display: block;
                        height: 30px;
                        width: auto;
                        margin: auto 10px;
                        @include round(50%);
                    }

                    &.divider {
                        font-weight: bold;
                        font-size: 0.7em;
                        padding: 5px 10px;
                    }

                    .status {
                        position: absolute;
                        top: 18px;
                        right: 18px;
                        font-size: 0.5em;
                        color: $grey;

                        &.online {
                            color: $green;
                        }
                    }
                }

            }
        }

        &.closed {
            height: 35px;

            .body,
            .footer {
                display: none;
                background: transparent;
                height: 0;
            }
        }
    }
}

.message-wrap {
    position: relative;
    margin: 10px 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding-left: 50px;

    &.direction_rtl {
        flex-direction: row-reverse;
        margin-right: 10px;

        .message {
            &:last-child {
                .triangle {
                    display: none;
                }
            }

            flex-direction: row-reverse;

            span {
                background: $super-light-blue;
            }
        }
    }

    .message {

        position: relative;
        display: flex;
        flex: auto 1;
        flex-direction: row;

        &:last-child {
            .triangle {
                display: block;
                border-style: solid;
                width: 0px;
                height: 0px;
                line-height: 0px;
                border-color: transparent transparent $light transparent;
                border-width: 0 0 7px 10px;
                _border-color: #000 #000 $light #000;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                position: absolute;
                bottom: 3px;
                left: -7px;
            }
        }

        span {
            position: relative;
            display: inline-block;
            padding: 7px 25px 5px 10px;
            @include round(3px);
            background: $light;
            max-width: 250px;
            word-break: break-word;
            white-space: normal;
            margin-bottom: 3px;
            line-height: 18px;
            @include box-shadow(0px, 1px, 1px, rgba(0,0,0,0.2));
        }

        .message-info {
            i {
                position: absolute;
                right: 5px;
                bottom: 10px;
                color: #d1d1d1;
                font-size: 0.65em;

                &.read {
                    color: $blue;
                }
            }
        }
    }

    .avatar {
        max-width: 30px;
        max-height: 30px;
        @include round(50%);
        overflow: hidden;
        margin: 10px;
        position: absolute;
        bottom: -10px;
        left: 0;
    }

    .img-wrap {
        max-width: 150px;
    }

    img.image {
        height: 200px;
        width: auto;
        @include round(5px);
    }
}

.chat-wrap {
    position: absolute;
    top: 50px;
    left: 0;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    background-color: #F4F8F9;

    .header {
        display: flex;
        height: 42px;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid $border;
        background: $light;

        .title {
            flex-basis: 100%;
            overflow: hidden;
            text-align: center;
            font-weight: bold;
        }

        .icon {
            font-size: 1.5em;
            margin: 2px 5px;
        }
    }

    .left {
        flex: 0 0 25%;
        max-width: 340px;
        min-width: 240px;
        overflow-y: auto;
        background-color: #FFF;

        .search {
            display: block;
            padding: 12px;
            position: relative;

            label {
                border: 1px solid #bdc7d8;
                box-sizing: border-box;
                cursor: default;
                display: inline-block;
                font-weight: normal;
                margin: 0;
                position: relative;
                vertical-align: middle;
                border: none;
                width: 100%;

                &:before {
                    background-image: url("https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/T7-56nOj8xk.png");
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: -34px -324px;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    left: 8px;
                    position: absolute;
                    top: 10px;
                    width: 12px;
                }

                input {
                    background: transparent;
                    border: 0;
                    margin: 0;
                    outline: 0;
                    padding: 0;
                    width: 100%;
                    background-color: #f6f7f9;
                    border-radius: 5px;
                    border-style: none;
                    box-sizing: border-box;
                    font-family: 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif';
                    font-size: 14px;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 28px;
                }
            }
        }



        .messages {
            max-height: calc(100vh - 220px);
            height: calc(100vh - 220px);
            overflow: auto;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                padding: 15px;
                position: relative;
                cursor: pointer;

                &:hover, &.active {
                    background: #F5F8F9;
                }

                .date {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 16px;
                }

                p {
                    margin: 0;

                    &.last_message {
                        max-width: 220px;
                        color: rgba(153, 153, 153, 1);
                    }
                }

                img {
                    height: 40px;
                    width: 40px;
                    @include round(50%);
                    margin-right: 15px;
                }

                .status {
                    position: absolute;
                    font-size: 0.8em;
                    width: 15px;
                    height: 15px;
                    top: 43px;
                    left: 43px;
                    background: #ddd;
                    border: 2px solid $light;
                    @include round(50%);

                    &.online {
                        background: rgba(141,194,52,1);
                        border: 2px solid $light;
                    }
                }



                &.new {
                    p {
                        color: #F44336;
                        font-weight: bold;
                    }
                }

                &.new_message {
                    p {
                        font-weight: bold;
                        color: #000;
                    }
                }
            }
        }
    }

    .left-client {
        flex: 0 0 25%;
        max-width: 340px;
        min-width: 240px;
        overflow-y: auto;
        background-color: #fff;
        height: auto;
        margin-top: 40px;
        margin-left: 65px;
        border-radius: 10px;

        @include box-shadow(0px, 2px, 4px, rgba(0,0,0,0.05));


        .menu{
          display: block;
          text-transform: uppercase;

          background: #f5f5f5;
          border-radius: 5px;
          padding: 6px 10px;
          overflow: hidden;


          a:link{

            text-decoration: none;
          }

          a:hover{
            text-decoration: none;
            font-weight: bold;
          }

          a:active{
            font-weight: bold;
          }
          a:visited{
            font-weight: bold;
          }


        }

        .search {
            display: block;
            padding: 12px;
            position: relative;

            label {
                border: 1px solid #bdc7d8;
                box-sizing: border-box;
                cursor: default;
                display: inline-block;
                font-weight: normal;
                margin: 0;
                position: relative;
                vertical-align: middle;
                border: none;
                width: 100%;

                &:before {
                    background-image: url("https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/T7-56nOj8xk.png");
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: -34px -324px;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    left: 8px;
                    position: absolute;
                    top: 10px;
                    width: 12px;
                }

                input {
                    background: transparent;
                    border: 0;
                    margin: 0;
                    outline: 0;
                    padding: 0;
                    width: 100%;
                    background-color: #f6f7f9;
                    border-radius: 5px;
                    border-style: none;
                    box-sizing: border-box;
                    font-family: 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif';
                    font-size: 14px;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 28px;
                }
            }
        }

        .messages {
            max-height: calc(100vh - 220px);
            height: calc(100vh - 220px);
            overflow: auto;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                padding: 15px;
                position: relative;
                cursor: pointer;

                &:hover, &.active {
                    background: #F5F8F9;
                }

                .date {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 16px;
                }

                p {
                    margin: 0;

                    &.last_message {
                        max-width: 220px;
                        color: rgba(153, 153, 153, 1);
                    }
                }

                img {
                    height: 40px;
                    width: 40px;
                    @include round(50%);
                    margin-right: 15px;
                }

                .status {
                    position: absolute;
                    font-size: 0.8em;
                    width: 15px;
                    height: 15px;
                    top: 43px;
                    left: 43px;
                    background: #ddd;
                    border: 2px solid $light;
                    @include round(50%);

                    &.online {
                        background: rgba(141,194,52,1);
                        border: 2px solid $light;
                    }
                }



                &.new {
                    p {
                        color: #F44336;
                        font-weight: bold;
                    }
                }

                &.new_message {
                    p {
                        font-weight: bold;
                        color: #000;
                    }
                }
            }
        }
    }

    .content {
        display: flex;
        flex: 3;
        flex-direction: column;
        min-width: 0;

        .stack {
            display: flex;
            flex: 3;
            flex-direction: column;
            min-width: 0;

            .new-chat {
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100vh - 216px);
                max-height: calc(100vh - 216px);
            }

            .chat {
                display: flex;
                flex: 1 1 0;
                flex-direction: row-reverse;

                .info {
                    flex: 0 2 33.33%;
                    max-width: 420px;
                    min-width: 200px;
                    border-left: 1px solid $border;

                    p {
                        margin: 0;
                    }

                    .block {
                        &:not(:last-child) {
                            border-bottom: 1px solid $border;
                        }
                        padding: 15px;
                    }

                    .action {
                        font-size: 1.1em;
                        width: 30px;
                        text-align: center;
                        cursor: pointer;
                    }

                    label {
                        font-weight: normal;
                        color: rgba(0, 0, 0, .40);
                        font-size: 1em;
                    }

                    .data {
                        display: flex;
                        flex: 1 1 0;
                        margin: 0;
                    }

                    .chat {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                    }

                    .contact {
                        align-items: center;

                        .data {
                            align-items: center;
                        }

                        img {
                            height: 50px;
                            width: auto;
                            @include round(50%);
                            margin-right: 15px;
                        }
                    }

                    .attendant {
                        @extend .contact;

                        .btn {
                            height: 34px;
                            width: 90px;
                        }
                    }

                    .status {
                        @extend .contact;

                        .label {
                            width: 30px;
                            height: 30px;
                            margin: 0px 25px 0px 10px;
                        }

                        .btn {
                            height: 34px;
                            width: 80px;
                        }

                        .dropdown-menu {
                            a {
                                padding: 3px 20px 3px 30px;

                                span.label {
                                    display: block;
                                    position: absolute;
                                    top: 6px;
                                    left: 7px;
                                    width: 15px;
                                    height: 15px;
                                    margin:0px;
                                    padding:0px;
                                }
                            }
                        }
                    }

                    .company {
                        align-items: center;
                    }
                }

                .presentation {
                    flex: 1 1 10%;
                    display: flex;
                    flex-direction: column;
                    border-left: 1px solid $border;

                    .body {
                        padding: 0;
                        margin: 0;
                        height: calc(100vh - 191px);
                        max-height: calc(100vh - 191px);
                        overflow: auto;
                        background: #f1efec;
                    }

                    .actions {
                        background: #fff;
                        display: flex;
                        flex: auto 1 1;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin: 0;
                        border-top: 1px solid $border;
                        max-height: 50px;
                        height: 50px;
                        padding: 0 15px;

                        * {
                            background: none;
                            border: none;
                            font-size: 0.98em;
                        }

                        textarea {
                            resize: none;
                            width: 100%;
                            height: 100%;
                            line-height: 44px;
                        }

                        .fa {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }

    .content-client {
        display: flex;
        flex: 3;
        flex-direction: column;
        min-width: 0;
        overflow-y: auto;

        .btn-entry {
            border-color: #337ab7;
            color: #337ab7;
        }

        .searchbar{
            margin-top: 40px;
            height: 50px;
            background-color: #EAEEEF;
            border-radius: 5px;
            padding: 5px;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            color: #C9D3D5;

            @include box-shadow(0px, 2px, 4px, rgba(0,0,0,0.05));
        }

        .search_input{
            color: #000;
            border: 0;
            outline: 0;
            background: none;
            line-height: 35px;
            font-size: 22px;
            transition: width 0.4s linear;

        }


        .searchbar:hover > .search_input{
        //    padding: 0 10px;
            width: 450px;
            transition: width 0.4s linear;

        }

        .searchbar:hover > .search_icon{
        //    background: white;
            color: #4682B4;
        }

        .search_icon{
            height: 40px;
            width: 40px;
            background: #EAEEEF;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: none;
            color:#C9D3D5;
        }

        .table-box{
          background-color: #fff;
          width: 95%;
          height: auto;
          margin-top: 20px;
          border-radius: 15px;
          margin-left: auto;
          margin-right: auto;
          padding: 20px;

          overflow-y: auto;

          @include box-shadow(0px, 2px, 4px, rgba(0,0,0,0.05));

        }

        .stack {
            display: flex;
            flex: 3;
            flex-direction: column;
            min-width: 0;

            .new-chat {
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100vh - 216px);
                max-height: calc(100vh - 216px);
            }

            .chat {
                display: flex;
                flex: 1 1 0;
                flex-direction: row-reverse;

                .info {
                    flex: 0 2 33.33%;
                    max-width: 420px;
                    min-width: 200px;
                    border-left: 1px solid $border;

                    p {
                        margin: 0;
                    }

                    .block {
                        &:not(:last-child) {
                            border-bottom: 1px solid $border;
                        }
                        padding: 15px;
                    }

                    .action {
                        font-size: 1.1em;
                        width: 30px;
                        text-align: center;
                        cursor: pointer;
                    }

                    label {
                        font-weight: normal;
                        color: rgba(0, 0, 0, .40);
                        font-size: 1em;
                    }

                    .data {
                        display: flex;
                        flex: 1 1 0;
                        margin: 0;
                    }

                    .chat {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                    }

                    .contact {
                        align-items: center;

                        .data {
                            align-items: center;
                        }

                        img {
                            height: 50px;
                            width: auto;
                            @include round(50%);
                            margin-right: 15px;
                        }
                    }

                    .attendant {
                        @extend .contact;

                        .btn {
                            height: 34px;
                            width: 90px;
                        }
                    }

                    .status {
                        @extend .contact;

                        .label {
                            width: 30px;
                            height: 30px;
                            margin: 0px 25px 0px 10px;
                        }

                        .btn {
                            height: 34px;
                            width: 80px;
                        }

                        .dropdown-menu {
                            a {
                                padding: 3px 20px 3px 30px;

                                span.label {
                                    display: block;
                                    position: absolute;
                                    top: 6px;
                                    left: 7px;
                                    width: 15px;
                                    height: 15px;
                                    margin:0px;
                                    padding:0px;
                                }
                            }
                        }
                    }

                    .company {
                        align-items: center;
                    }
                }

                .presentation {
                    flex: 1 1 10%;
                    display: flex;
                    flex-direction: column;
                    border-left: 1px solid $border;

                    .body {
                        padding: 0;
                        margin: 0;
                        height: calc(100vh - 191px);
                        max-height: calc(100vh - 191px);
                        overflow: auto;
                        background: #f1efec;
                    }

                    .actions {
                        background: #fff;
                        display: flex;
                        flex: auto 1 1;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin: 0;
                        border-top: 1px solid $border;
                        max-height: 50px;
                        height: 50px;
                        padding: 0 15px;

                        * {
                            background: none;
                            border: none;
                            font-size: 0.98em;
                        }

                        textarea {
                            resize: none;
                            width: 100%;
                            height: 100%;
                            line-height: 44px;
                        }

                        .fa {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }

}

.panel-chat {
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
    box-shadow: none;

    * {
        background: none;
        border: none;
        font-size: 0.98em;
    }

    .panel-heading {
        background: #f6f7f9;
        border: none;
        padding: 5px 10px;
        color: #FFF;

        .status {
            border-radius: 50%;
            display: inline-block;
            margin: 0 3px 1px 0;
            vertical-align: middle;
            width: 10px;
            height: 10px;

            &.online {
                background: #42b72a;
            }

            &.offline {
                background: #b82a2a;
            }
        }

        &::after {
            content: ".";
            display: block;
            clear: both;
            visibility: hidden;
            height: 0;
        }

        a {
            text-decoration: none;
            color: $dark;
            font-weight: bold;
            float: left;
        }

        a.chatClose {
            text-decoration: none;
            max-width: 11px;
            width: 11px;
            float: right;
        }
    }

    &.new-chat {
        display: flex;
        flex: 1 1 0;
        align-items: center;
        justify-content: center;
        border: none;

        .panel-heading {
            background: #f44336;

            a {
                color: $light;
            }
        }

        .panel-body {
            background: $light;
            padding: 15px;

            .label {
                color: $grey;
                padding: 0;
                font-size: 0.8em;
            }
        }

        .panel-footer {
            padding: 0 10px;
            border: none;

            a {
                background: $main-color;
            }
        }
    }
}

.empty_chat {

    div {
        max-width: 600px;
        margin: 0 auto;

        blockquote {
            text-align: left;
        }
    }

    img {
        height: 300px;
        width: auto;
    }
}
