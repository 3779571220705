.notifications-table{
    padding:0 300px 0 300px;
    tr{
        td{
            background-color: #FFF;
            a{
                white-space: normal;
                text-decoration: none;
                padding: 5px 10px;
                min-width: 400px;
                display: flex;
                align-items: center;
                color:#455a64;

                img {
                    margin-right:12px;
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                }
            }
            &:hover{
                background-color: #f1f1f1;
            }
        }

        &.unread {
            td {
                background-color: #edf2fa;
            }
        }
    }


}
